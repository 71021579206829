import { useEffect, useState } from 'react'
import './Skills.css'

export const Skills = () => {
    const [sizeIcon, setSizeIcon] = useState('5rem')

    //Para dispositívos que já possuem < 433px de largura
    useEffect(() => {
      if(window.screen.availWidth < 433){
        setSizeIcon('4rem')
      }
    },[])

    //Para se adaptar ao tamanho da tela quando for < 434px
    window.addEventListener('resize', ()=>{
        const body = document.getElementsByTagName('body')
        if(body[0].clientWidth < 417){
            setSizeIcon('4rem')
        }
        if(body[0].clientWidth >= 417){
            setSizeIcon('5rem')
        }
    })

    return (
        <section id='skills' className="skills">
            <div className="title-box">
                <h2>#SKILLS</h2>
            </div>
            <div className="wrapper">
                <div className="skills-icon-list">
                <box-icon name='html5' type='logo'  color='#f7f9ff' size={sizeIcon}></box-icon>
                <box-icon name='css3' type='logo'   color='#f7f9ff' size={sizeIcon}></box-icon>
                <box-icon name='bootstrap' type='logo'  color='#f7f9ff' size={sizeIcon}></box-icon>
                <box-icon type='logo' name='tailwind-css' color='#f7f9ff' size={sizeIcon}></box-icon>        
                <box-icon name='javascript' type='logo'   color='#f7f9ff' size={sizeIcon}></box-icon>
                <box-icon name='react' type='logo'  color='#f7f9ff' size={sizeIcon}></box-icon>
                <box-icon name='firebase' type='logo'   color='#f7f9ff' size={sizeIcon}></box-icon>
                </div>
                <div className="line"></div>
                <div className="skills-list">
                    <span>HTML 5</span>
                    <span>CSS 3</span>
                    <span>BOOTSTRAP 5</span>
                    <span>TAILWIND CSS</span>
                    <span>JAVASCRIPT</span>
                    <span>REACT JS</span>
                    <span>FIREBASE</span>
                </div>
            </div>
        </section>
    )
}
