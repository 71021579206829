import './About.css'
import profile2 from '../../images/profile2_2.png'

export const About = () => {
  return (
    <section id='about' className="about">
        <div className="title-box">
            <h2>#ABOUT</h2>
        </div>
        <div className="about-wrapper">
            <div className="about-img-box">
                <img className="about-img" src={profile2} alt=""/>
            </div>
            <div className="about-text-box">
                <p>Olá! Me chamo Bruno, e estou a procura da minha primeira experiência de trabalho como desenvolvedor web front-end.</p>
                <p>Estou cursando o quinto semestre em Análise e Desenvolvimento de Sistemas pela Estácio, além disso gosto de procurar conteúdo e estudar por conta própria.</p> 
                <p>Busco evoluir e me tornar um desenvolvedor capaz de resolver qualquer problema.</p>
            </div>
        </div>
    </section>
  )
}
