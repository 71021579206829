import './Header.css'
import 'boxicons'
import { useState } from 'react'

export const Header = () => {
    const [colorMenu, setColorMenu] = useState('#f7f9ff')
    const [colorClose, setColorClose] = useState('#f7f9ff')

    function onHoverMenu(){
    setColorMenu('#a2a4ac')
    }

    function outHoverMenu(){
        setColorMenu('#f7f9ff')
    }

    function onHoverClose(){
        setColorClose('#a2a4ac')
    }

    function outHoverClose(){
        setColorClose('#191c1d')
    }

    function openMenu(){
        let sideNav = document.getElementsByClassName('side-nav')
        sideNav[0].style.width ='300px'
    }

    function closeMenu(){
        setColorMenu('#f7f9ff')
        let sideNav = document.getElementsByClassName('side-nav')
        sideNav[0].style.width ='0px'
        
    }
    
    window.addEventListener('scroll', () => {

            if(window.scrollY > 0){
                let header = document.getElementById('beginning')
                header.classList.add('shadow')
            }else 
                if(window.scrollY === 0){
                    const header = document.getElementById('beginning')
                    header.classList.remove('shadow')
                }
  
    })

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <>
            <header id="beginning">
                <span ><a href="#section-hero">Bruno Felipe</a></span>
                <ul>
                    <li><a href="#projetos">Projetos</a></li>
                    <li><a href="#skills">Skills</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href='' onClick={() => {openInNewTab('https://brunofelipe.dev/static/media/Currículo.pdf')}}>Resume</a></li>
                    <li onMouseEnter={onHoverMenu} onMouseLeave={outHoverMenu} onClick={openMenu} className='menu'><box-icon color={colorMenu} name='menu' ></box-icon></li>
                </ul>
            </header>
            <div className="side-nav">
                <ul>
                    <li  onClick={closeMenu} className="side-nav-element close"><box-icon onMouseEnter={onHoverClose} onMouseLeave={outHoverClose} color={colorClose} name='x' ></box-icon></li>
                    <li className="side-nav-element"><a href="#projetos">Projetos</a></li>
                    <li className="side-nav-element"><a href="#skills">Skills</a></li>
                    <li className="side-nav-element"><a href="#about">About</a></li>
                    <li className="side-nav-element"><a href='' onClick={() => {openInNewTab('https://brunofelipe.dev/static/media/Currículo.pdf')}}>Resume</a></li> 
                </ul>
            </div>
        </>
    )
}
