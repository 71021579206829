import './Projetos.css'
import template1 from '../../images/template1.png'
import template2 from '../../images/template2.png'
import template3 from '../../images/template3.png'

export const Projetos = () => {
  return (
    <section id="projetos" className="projetos">
      <div className="title-box">
        <h2>#PROJETOS</h2>
      </div>
      <div className="container-grid">
        <div className="projeto">
          <img onClick={() => window.location.href="https://exacttime.brunofelipe.dev/"} className="projeto-img" src={template1} alt=""/>
          <div className="projeto-text-box">
            <span  onClick={() => window.location.href="https://exacttime.brunofelipe.dev/"}>Ecommerce de Relógios</span>
            <a href="https://exacttime.brunofelipe.dev/">exacttime.brunofelipe.dev</a>
          </div>
        </div>
        <div className="projeto">
        <img onClick={() => window.location.href="https://page.brunofelipe.dev/"} className="projeto-img" src={template2} alt=""/>
          <div className="projeto-text-box">
            <span onClick={() => window.location.href="https://page.brunofelipe.dev/"}>Landing Page Coffee theme</span>
            <a href="https://page.brunofelipe.dev/">page.brunofelipe.dev</a>
          </div>
        </div>
        <div className="projeto">
          <img onClick={() => window.location.href="https://ecommerce.brunofelipe.dev/"} className="projeto-img" src={template3} alt=""/>
          <div className="projeto-text-box">
            <span onClick={() => window.location.href="https://ecommerce.brunofelipe.dev/"}>Ecommerce espírito Natalino</span>
            <a href="https://ecommerce.brunofelipe.dev/">ecommerce.brunofelipe.dev</a>
          </div>
        </div>
      </div>
    </section>
  )
}
