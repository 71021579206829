import { Link } from 'react-router-dom'
import './Footer.css'

export const Footer = () => {
    return (
        <footer>
            <div className="title-box">
                <h2>#ME ENCONTRE EM</h2>
            </div>
            <div className="logo-box">
                <a href={"https://www.linkedin.com/in/obrunofelipe/"}><box-icon  color='#f7f9ff' size='5rem' type='logo' name='linkedin-square'></box-icon></a>
                <a href={"https://github.com/Obrunofelipe"}><box-icon  color='#f7f9ff' size='5rem' name='github' type='logo' ></box-icon></a>
            </div>
        </footer>
    )
}
