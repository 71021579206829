import './SectionHero.css'
import profile from '../../images/profile_2.png'
import 'boxicons'

export const SectionHero = () => {
    return (
        <section id='section-hero' className='section-hero'>
            <div className="profile-box">
                <img className="profile" src={profile} alt=""/>
            </div>
                
            <div className="text-box">
                <span className="name">Bruno Felipe</span>
                <span className="role">Desenvolvedor Web Front-end </span>
                <span className='location'><box-icon type='solid' name='location-plus' size='1rem' color='#f7f9ff'></box-icon>  Natal, RN</span>
            </div>
        </section>
    )
}
